import {useEffect} from 'react'
import {RegisterFormContent} from './RegisterFormContent'

const RegisterForm = () => {

  return (
    <>
        <div className="container mt-10">
            {/* <div className="row"> */}
            <div className="col-12">
                <h1 className="text-center"><strong>สมัครสมาชิก</strong></h1>
                <RegisterFormContent />
            </div>
        </div>
    </>
  )
}

export {RegisterForm}
