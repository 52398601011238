/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      <AsideMenuItem

        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='ข้อมูลสมาชิก'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/admin/admin'
        icon='/media/icons/duotune/general/gen051.svg'
        title='ข้อมูลผู้ดูแลระบบ'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/apps/consent/consent'
        icon='/media/icons/duotune/general/gen051.svg'
        title='ข้อมูลเงื่อนไขการยอมรับ'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/apps/otpsms/otpsms'
        icon='/media/icons/duotune/general/gen051.svg'
        title='การเชื่อมต่อ API'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/apps/activity-log/log'
        icon='/media/icons/duotune/general/gen051.svg'
        title='ประวัติการใช้งาน'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/apps/activity-log-login/log-login'
        icon='/media/icons/duotune/general/gen051.svg'
        title='ประวัติการเข้าสู่ระบบ'
        fontIcon='bi-layers'
      />

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
    </>
  )
}
