import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {MemberCancelAccountWrapper, MemberCancelAccount} from './member-cancel-account/MemberCancelAccount'

// const registerBreadcrumbs: Array<PageLink> = [
//   {
//     title: 'Register',
//     path: '/alert-login',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

const MemberCancelAccountPage = () => {
  // console.log('AlertLogin');
  // return;
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='member-cancel-account'
          element={
            <>
              {/* <PageTitle>Register</PageTitle> */}
              <MemberCancelAccountWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<MemberCancelAccount />} />
    </Routes>
  )
}

export default MemberCancelAccountPage
