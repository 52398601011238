
// import {QueryRequestProvider} from './core/QueryRequestProvider'
// import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {AdminListHeader} from './components/header/AdminListHeader'
// import {LoginForm} from '../form/LoginForm'
// import {ConsentModalShow} from '../modal/ConsentModalShow'
import {KTCard} from '../../../../../_metronic/helpers'
import {useState, useEffect} from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery,} from '@apollo/client'
import SG_BG from '../../../../../media/bg.png'
import liff from '@line/liff';
import {firebaseDB} from '../../../../../connect/firebase'
import {collection, addDoc, getDocs, getDoc, updateDoc, deleteDoc, query, onSnapshot} from '@firebase/firestore'
import {doc, where} from 'firebase/firestore'
import { useNavigate } from "react-router-dom";
import {LoginLineLoading} from '../components/loading/LoginLineLoading'
import {Logout, AuthPage, useAuth} from '../../../../modules/auth'
import {useMacAdress} from '../core/GetMacAddress'
import axios from 'axios'
// import { HttpLink } from 'apollo-link-http';
// const link = new HttpLink({ uri: 'http://127.0.0.1:5001/reactjs-function/asia-southeast1/graphql' });
const client = new ApolloClient({
  uri: 'https://asia-southeast1-uat-mkt.cloudfunctions.net/graphql',
  cache: new InMemoryCache(),
});

// const GET_CUSTOMER_PAGE = gql`
// query ExampleQuery($limit: Int!, $offset: Int!){
//   customer (limit: $limit, offset: $offset){
//     id
//     firstname
//     lastname
//     email
//     position
//     role
//   }
// }
// `;

const LoginLine =  () => {
  console.log(useMacAdress());
  const navigate = useNavigate();
  const [lineUserLoginId, setLineUserLoginId] = useState<string>();
  // const [checkMemberFirstLogin, setCheckMemberFirstLogin] = useState(true);
  const {saveSetAuthMember, setCurrentMember, checkLoginMember, setCheckLoginMember} = useAuth()
  const [dataOnFirebase, setDataOnFirebase] = useState();
  const [lineToken, setLineToken]:any = useState();

  const [ip,setIP] = useState('');


  // const [lineUserLoginId, setLineUserLoginId] = useState<string>();
  // setLineUserId('5555555');

  // const getData = async()=>{
  //   const res = await axios.get('https://geolocation-db.com/json/')
  //   // console.log(res.data);
  //   setIP(res.data.IPv4)
  // }

  // useEffect(()=>{
  //     //passing getData method to the lifecycle method
  //     getData()
  // },[])


  let agent = navigator.userAgent;

  console.log('agent', agent);

  // liff.init({ liffId: '1657455630-LVDlpvqW' })
     liff.init({ liffId: '1657455630-eXPl1nAw' })

  .then(async () => {

    if(liff.isLoggedIn()){
      // console.log('liff.isInClient() ', liff.getIDToken() )
      setLineToken(liff.getIDToken());
      let getProfile = await liff.getProfile();
      let os = await liff.getOS();
      console.log('liff', getProfile);
      // console.log(typeof getProfile.userId);
      // setLineUserId(getProfile.userId);
      // const line_user_id = JSON.stringify(getProfile.userId);
      const check_member =  await query(collection(firebaseDB, "customer"), where("line_user_login", "==", getProfile.userId));
            // firebaseDB.firestore().collection('customer').where('id_card', "==",  val.id_card).onSnapshot(this.onCollectionUpdate)
            // console.log('check_member',check_member);

        var unsuscribe = await onSnapshot(check_member, (querySnapshot)  =>  {
        // const user = JSON.parse(""+window.sessionStorage.getItem("Customerdata"));
        // if (user) {
          console.log(querySnapshot.docs);
          if(querySnapshot.docs.length > 0){

            const dataMember = querySnapshot.docs.map((d:any) => ({ id: d.id, ...d.data() }))
            const {data: auth} = dataMember[0];
            // const dataMember_str = JSON.stringify(dataMember[0]);
            // const replace_dataMember_str = dataMember_str.replace(/\\/g, "");
            // console.log(dataMember[0]);

            // if(dataMember[0].otp_first_login === true){
              // setCurrentMember(dataMember[0])
              // saveSetAuthMember(dataMember[0])
              setDataOnFirebase(dataMember[0]);


            // }else{
            //   setCheckMemberFirstLogin(false);
            // }
            // return dataMember[0];
            // setDataOnFirebase(dataMember[0]);
            // const final_datdataMember[0]aMember = JSON.parse(""+replace_dataMember_str);

            // console.log(final_dataMember);
            // sessionStorage.setItem('memberData', JSON.stringify(dataMember))
            // return



          }else{
            // console.log('Login else',getProfile.userId);
            // // console.log('No data member.'
            // Swal.fire({
            //   icon: 'error',
            //   title: 'Login Fail.',
            //   text: 'ID Card or Number Phone invalid.',
            // })
            // window.sessionStorage.setItem("lineUserID",JSON.stringify(getProfile.userId));
            setLineUserLoginId(JSON.stringify(getProfile.userId));
            // setLineUserLoginId('afsasfasafsa');
            // setLineUserId(""+getProfile.userId);
            // navigate('/frontend/register/register')
            // console.log('after navigate');
          }

        })

        // const res = await axios.get('http://127.0.0.1:5001/mkt-lineoa/asia-southeast1/graphql/api/getLogLogin?line_user_id=U771f8e5b3fdfbd1e781561734d49c158')
        // console.log(res);

        await axios.get("https://asia-southeast1-uat-mkt.cloudfunctions.net/graphql/api/getLogLogin?line_user_id=" + getProfile.userId + "&line_token=" + liff.getIDToken())
        .then(async function (res)  {
          console.log(res.data.data_logged);
          if(res.data.data_logged !== null && res.data.data_logged !== true){
              await setCheckLoginMember(res.data.data_logged[0]);
          }else if(res.data.data_logged === true){
            await setCheckLoginMember(true);
          }else{
            await setCheckLoginMember('Not Login');
          }
        })
        .catch(function (error) {
          console.log(error);
        });


        // const check_member_login = await query(collection(firebaseDB, "activity_log_login"), where("line_user_id", "==", getProfile.userId), where("login_status", "==", true));
        // var unsuscribe_member = await onSnapshot(check_member_login, (querySnapshot) => {
        // // const user = JSON.parse(""+window.sessionStorage.getItem("Customerdata"));
        // // if (user) {
        //   // console.log('asdsadsadsav55151',querySnapshot.docs);
        //   if(querySnapshot.docs.length > 0){
        //     const dataCheckMemberLogin = querySnapshot.docs.map((d:any) => ({ id: d.id, ...d.data() }))
        //     // console.log('asdsadsadsav55151',dataCheckMemberLogin);
        //     setCheckLoginMember(dataCheckMemberLogin[0]);
        //   }else{
        //     setCheckLoginMember('Not Login');
        //   }
        // })


      //   // firebaseDB.firestore().collection('customer').where('id_card', "==",  val.id_card).onSnapshot(this.onCollectionUpdate)
      //   // console.log('check_member',check_member);





    }else{
      await liff.login()
    }

  })
  .catch(err=>{console.log(err)})

  // const {itemIdForUpdate} = useListView()
  // const [page, setPage] = useState(0);
  // const {data, loading, error} = useQuery(GET_CUSTOMER_PAGE,{
  //   client,
  //   variables: {
  //     limit: 10,
  //     offset: page * 10,
  //   }

  // })



  const getSessionMember = localStorage.getItem('kt-auth-react-v')
  const sessionMember = JSON.parse(""+getSessionMember)

  console.log('sessionMember', sessionMember);

  if(dataOnFirebase !== undefined){
      // console.log('dataMember[0]', dataOnFirebase);
    // setDataBeforeSaveMember(dataOnFirebase);
    // return
    // if(dataOnFirebase){
    //   // console.log('555');
    //   navigate('/frontend/register/register')
    // }else{
      // console.log('666');



      console.log('checkLoginMember', checkLoginMember);
      if(dataOnFirebase['status'] !== false){
        if(checkLoginMember !== undefined){
          if(checkLoginMember === 'Not Login'){
            const param = {
              "line_user_id": dataOnFirebase['line_user_login'],
              "line_token" : lineToken
            }
            axios.post("https://asia-southeast1-uat-mkt.cloudfunctions.net/graphql/api/createLogLogin", param)
            .then(function (res) {
              console.log(res);
            })
            .catch(function (error) {
              console.log(error);
            });
            saveSetAuthMember(dataOnFirebase);
            setCurrentMember(dataOnFirebase);
            navigate("/frontend/tiecredit/tiecredit");
          }else if(checkLoginMember === true){
            saveSetAuthMember(dataOnFirebase);
            setCurrentMember(dataOnFirebase);
            navigate("/frontend/tiecredit/tiecredit");
          }else{
            navigate("/alert-login");
          }

        }
      }else{
        navigate("/member-cancel-account");
      }

    // }
  }
  // else{
  //   console.log('LoginLine 1');
  //   navigate('/frontend/register/register')
  // }

  if(lineUserLoginId !== undefined){
    window.sessionStorage.setItem("lineUserID",lineUserLoginId);
    console.log('LoginLine 2');
    // setLineUserId('sacsacsac');
    navigate('/frontend/register/register')
  }

  // console.log('lineUserId6555',lineUserId);
  return (
    <>
      {/* <KTCard className="bg-white"> */}
        {/* <AdminListHeader /> */}
      <div
        className='d-flex flex-column flex-column-fluid '
        style={{
          backgroundImage: `url(${SG_BG})` ,
          // backgroundColor: 'red'
        }}
      >
        <LoginLineLoading/>
      </div>
      {/* </KTCard> */}
      {/* {itemIdForUpdate !== undefined && <ConsentModalShow />} */}

    </>
  )
}

const LoginLineWrapper = () => (
  <ApolloProvider client={client}>
{/* //     <QueryRequestProvider> */}
{/* //       <QueryResponseProvider> */}
        {/* <ListViewProvider> */}
          <LoginLine />
        {/* </ListViewProvider> */}
{/* //       </QueryResponseProvider> */}
{/* //     </QueryRequestProvider> */}
    </ApolloProvider>
)

export {LoginLineWrapper, LoginLine}
