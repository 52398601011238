
// import {QueryRequestProvider} from './core/QueryRequestProvider'
// import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {AdminListHeader} from './components/header/AdminListHeader'
// import {LoginForm} from '../form/LoginForm'
// import {ConsentModalShow} from '../modal/ConsentModalShow'
import {KTCard} from '../../../../../_metronic/helpers'
import {useState, useEffect} from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery,} from '@apollo/client'
import SG_BG from '../../../../../media/bg.png'
import liff from '@line/liff';
import {firebaseDB} from '../../../../../connect/firebase'
import {collection, addDoc, getDocs, getDoc, updateDoc, deleteDoc, query, onSnapshot} from '@firebase/firestore'
import {doc, where} from 'firebase/firestore'
import { useNavigate } from "react-router-dom";
import {MemberCancelAccountLoading} from '../components/loading/MemberCancelAccountLoading'
import {updateLoglogin} from '../core/_requests'
import {Logout, AuthPage, useAuth} from '../../../../modules/auth'
import axios from 'axios'
// import { HttpLink } from 'apollo-link-http';
// const link = new HttpLink({ uri: 'http://127.0.0.1:5001/reactjs-function/asia-southeast1/graphql' });
const client = new ApolloClient({
  uri: 'https://asia-southeast1-uat-mkt.cloudfunctions.net/graphql',
  cache: new InMemoryCache(),
});

// const GET_CUSTOMER_PAGE = gql`
// query ExampleQuery($limit: Int!, $offset: Int!){
//   customer (limit: $limit, offset: $offset){
//     id
//     firstname
//     lastname
//     email
//     position
//     role
//   }
// }
// `;

const MemberCancelAccount =   () => {
  const navigate = useNavigate();
  // console.log('AlertLogin2');

  const {saveSetAuthMember, setCurrentMember, checkLoginMember, currentMember} = useAuth()
  // const {currentMember} = useAuth()
  const [dataOnFirebase, setDataOnFirebase] = useState();
  const [lineUserLoginId, setLineUserLoginId] = useState<string>();
  console.log('currentMember', currentMember);
  console.log('checkLoginMember', checkLoginMember);
  console.log('dataOnFirebase alert login', dataOnFirebase);
  // if(checkLoginMember === undefined){
  //   console.log('55555');
    // liff.logout();
    // liff.init({ liffId: '1657455630-LVDlpvqW' })
       liff.init({ liffId: '1657455630-eXPl1nAw' })
  .then(async () => {

    if(liff.isLoggedIn()){
      if(checkLoginMember === undefined){
        // console.log('5555')
        liff.logout();
        navigate("/frontend/login-line/login-line");
      }
    }
  })
  .catch(err=>{console.log(err)})

  // }

  const clickLogoutDevice =  () => {
    // console.log(55555555555);
    // liff.init({ liffId: '1657455630-LVDlpvqW' })
       liff.init({ liffId: '1657455630-eXPl1nAw' })
    .then(async () => {

      if(liff.isLoggedIn()){
        if(checkLoginMember === undefined){
          // console.log('5555')
          liff.logout();
          navigate("/frontend/login-line/login-line");
        }

        const param = {
          "line_user_id": checkLoginMember['line_user_id'],
          "line_token": liff.getIDToken()
        }
          await axios.post("https://asia-southeast1-uat-mkt.cloudfunctions.net/graphql/api/createLogLogin", param)
          .then(async function (res) {
            await updateLoglogin(checkLoginMember.id);

            const check_member =  await query(collection(firebaseDB, "customer"), where("line_user_login", "==", checkLoginMember.line_user_id));
              // firebaseDB.firestore().collection('customer').where('id_card', "==",  val.id_card).onSnapshot(this.onCollectionUpdate)
              // console.log('check_member',check_member);

            var unsuscribe = await onSnapshot(check_member, (querySnapshot)  =>  {
              // const user = JSON.parse(""+window.sessionStorage.getItem("Customerdata"));
              // if (user) {
              console.log(querySnapshot.docs);
              if(querySnapshot.docs.length > 0){

                const dataMember = querySnapshot.docs.map((d:any) => ({ id: d.id, ...d.data() }))
                const {data: auth} = dataMember[0];
                // const dataMember_str = JSON.stringify(dataMember[0]);
                // const replace_dataMember_str = dataMember_str.replace(/\\/g, "");
                // console.log(dataMember[0]);

                // if(dataMember[0].otp_first_login === true){
                  // setCurrentMember(dataMember[0])
                  // saveSetAuthMember(dataMember[0])
                setDataOnFirebase(dataMember[0]);


                // }else{
                //   setCheckMemberFirstLogin(false);
                // }
                // return dataMember[0];
                // setDataOnFirebase(dataMember[0]);
                // const final_datdataMember[0]aMember = JSON.parse(""+replace_dataMember_str);

                // console.log(final_dataMember);
                // sessionStorage.setItem('memberData', JSON.stringify(dataMember))
                // return



              }else{
                // console.log('Login else',getProfile.userId);
                // // console.log('No data member.'
                // Swal.fire({
                //   icon: 'error',
                //   title: 'Login Fail.',
                //   text: 'ID Card or Number Phone invalid.',
                // })
                // window.sessionStorage.setItem("lineUserID",JSON.stringify(getProfile.userId));
                setLineUserLoginId(JSON.stringify(checkLoginMember.line_user_id));
                // setLineUserId(""+getProfile.userId);
                // navigate('/frontend/register/register')
                // console.log('after navigate');
              }

            })
            // console.log(res);
            // saveSetAuthMember(dataOnFirebase);
            // setCurrentMember(dataOnFirebase);
            // navigate("/frontend/tiecredit/tiecredit");
          })
          .catch(function (error) {
            console.log(error);
          });

      }
    })
    .catch(err=>{console.log(err)})

  }

    if(dataOnFirebase !== undefined ){
        // const param = {"line_user_id": dataOnFirebase['line_user_login']}
        // axios.post("http://127.0.0.1:5001/mkt-lineoa/asia-southeast1/graphql/api/createLogLogin", param)
        // .then(function (res) {
        //   console.log(res);
          saveSetAuthMember(dataOnFirebase);
          setCurrentMember(dataOnFirebase);
          navigate("/frontend/tiecredit/tiecredit");
        // })
        // .catch(function (error) {
        //   console.log(error);
        // });


    }

  return (
    <>
      {/* <KTCard className="bg-white"> */}
        {/* <AdminListHeader /> */}
      <div
        className='d-flex flex-column flex-column-fluid '
        style={{
          backgroundImage: `url(${SG_BG})` ,
          // backgroundColor: 'red'
        }}
      >
        <div className="container mt-20">
        </div>
        <div className="container mt-20">
        </div>
        <div className="container mt-20">
        </div>
        <div className="container mt-20">
        </div>
        <div className="container bg-secondary ">
          <div className="d-flex justify-content-center pt-5">
            <h1 className="text-center">บัญชีของคุณได้ทำการยกเลิกบัญชีแล้ว</h1>
          </div>
          <div className="d-flex justify-content-center pb-5">
            <h1 className="text-center">หากต้องการใช้งานต่อกรุณาติดต่อเจ้าหน้าที่</h1>
          </div>
        </div>

      </div>
      {/* </KTCard> */}
      {/* {itemIdForUpdate !== undefined && <ConsentModalShow />} */}

    </>
  )
}

const MemberCancelAccountWrapper = () => (
  <ApolloProvider client={client}>
{/* //     <QueryRequestProvider> */}
{/* //       <QueryResponseProvider> */}
        {/* <ListViewProvider> */}
          <MemberCancelAccount />
        {/* </ListViewProvider> */}
{/* //       </QueryResponseProvider> */}
{/* //     </QueryRequestProvider> */}
    </ApolloProvider>
)

export {MemberCancelAccountWrapper, MemberCancelAccount}
