import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const AUTH_MEMBER_LOCAL_STORAGE_KEY = 'kt-auth-member-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth = JSON.parse(lsValue) 
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const getAuthMember = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_MEMBER_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth = JSON.parse(lsValue) 
    console.log('getAuthMember', auth);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH MEMBER LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    // console.log('lsValue', lsValue);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const setAuthMember = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }
  
  try {
    const lsValue = JSON.stringify(auth)
    const replace_value = lsValue.replace(/\\/g, "");
    // console.log('lsValue', replace_value.substring(0, 1));
    console.log('lsValue', replace_value.charAt(0));
    if(replace_value.charAt(0) == '"' ){
      localStorage.setItem(AUTH_MEMBER_LOCAL_STORAGE_KEY, replace_value.slice(1, -1))
    }else{
      localStorage.setItem(AUTH_MEMBER_LOCAL_STORAGE_KEY, replace_value)
    }
    // return
    
  } catch (error) {
    console.error('AUTH MEMBER LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }
  console.log('removeAuth',localStorage);
  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const removeAuthMember = () => {
  if (!localStorage) {
    return
  }
  console.log('removeAuth',localStorage);
  try {
    localStorage.removeItem(AUTH_MEMBER_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH MEMBER LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuth, getAuthMember, setAuth, setAuthMember,  removeAuth, removeAuthMember, AUTH_LOCAL_STORAGE_KEY, AUTH_MEMBER_LOCAL_STORAGE_KEY}
