import {useQuery, gql} from '@apollo/client'
const GET_MACADRESS = gql`
query GetMacAddress {
    getMacAddress {
      IP_MacAddress
    }
  }
`;

export const useMacAdress = () => {
    const queryMacAdress = useQuery(GET_MACADRESS);

    return queryMacAdress
}