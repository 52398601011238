import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AlertLoginWrapper, AlertLogin} from './alert-login/AlertLogin'

// const registerBreadcrumbs: Array<PageLink> = [
//   {
//     title: 'Register',
//     path: '/alert-login',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

const AlertLoginPage = () => {
  // console.log('AlertLogin');
  // return;
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='alert-login'
          element={
            <>
              {/* <PageTitle>Register</PageTitle> */}
              <AlertLoginWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<AlertLogin />} />
    </Routes>
  )
}

export default AlertLoginPage
