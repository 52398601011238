import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
  ID,
  // calculatedGroupingIsDisabled,
  // calculateIsAllDataSelected,
  // groupingOnSelect,
  initialListView,
  ListViewContextProps,
  // groupingOnSelectAll,
} from './helpers'
// import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
// import {useConsentList} from '../core/ConsentList'
import * as Yup from 'yup'

const ListViewContext = createContext<ListViewContextProps>(initialListView)

const ListViewProvider: FC = ({children}) => {
  console.log('initialListView', initialListView);
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  // const [consentRequired, setConsentRequired] = useState<Array<string>>(initialListView.consentRequired)
  const [consentFromDB, setConsentFromDB]:any = useState<Array<object>>([])
  const [consentPage, setConsentPage]:any = useState<number>(0)
  // const [consentCheck, setConsentCheck]:any = useState<object>({})
  // const data_consent_list = useConsentList();
  // console.log('data_consent_list', data_consent_list);
  // if(data_consent_list.loading !== true){
  //   data_consent_list.data.consent.forEach((val:any) => {
  //     consentCheck[val.consent_code] = val.consent_title
  //     // if(val.consent_code == 'consent01'){
  //     //   consentFromDB[val.consent_code] = Yup.bool().oneOf([true], 'You need to accept the terms and conditions');
  //     // }else{
  //     //   consentFromDB[val.consent_code] = Yup.bool().oneOf([true, false], 'You need to accept the terms and conditions');
  //     // }
  //       // register_member = Yup.object().shape({
  //       //     [val.consent_code] : Yup.bool().oneOf([true, false], 'You need to accept the terms and conditions')
  //       // })
  //       // registerMemberInsert[val.consent_code] = null;
  //       // consentCheck[val.consent_code] = false
  //   })
  // }
  // const [typeShow, setTypeShow] = useState<string>(initialListView.typeShow)
  // const {isLoading} = useQueryResponse()
  // const data = useQueryResponseData()
  // const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  // const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        // consentRequired,
        // setConsentRequired,
        consentFromDB,
        setConsentFromDB,
        consentPage,
        setConsentPage,
        // consentCheck,
        // setConsentCheck,
        // setTypeShow,
        // typeShow,
        // disabled,
        // isAllSelected,
        // onSelect: (id: ID) => {
        //   groupingOnSelect(id, selected, setSelected)
        // },
        // onSelectAll: () => {
        //   groupingOnSelectAll(isAllSelected, setSelected, data)
        // },
        // clearSelected: () => {
        //   setSelected([])
        // },
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
