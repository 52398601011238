// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "@firebase/firestore";
// import {getFirestore AS Firestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyAvcsEP7uG1eSyUMzAhvEav8_LTQ-FL9ok",
  authDomain: "uat-mkt.firebaseapp.com",
  projectId: "uat-mkt",
  storageBucket: "uat-mkt.appspot.com",
  messagingSenderId: "512708811499",
  appId: "1:512708811499:web:3d0317e4450f85e5f7fcae",
  measurementId: "G-3F9CVKCK4J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebaseDB =  getFirestore(app);
// const db =  getFirestore(app);
export {firebaseDB}
