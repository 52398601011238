import {useQuery, gql} from '@apollo/client'
const GET_CONSENT = gql`
query ConsentByStatus {
  consentByStatus {
    id
    consent_code
    consent_title
    descrition
    status
    consent_required
  }
}
`;

export const useConsentList = () => {
    const queryConsent = useQuery(GET_CONSENT);

    return queryConsent
}