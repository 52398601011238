import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AdminPage = lazy(() => import('../modules/apps/admin/AdminPage'))
  const ConsentPage = lazy(() => import('../modules/apps/consent/ConsentPage'))
  const OtpSmsPage = lazy(() => import('../modules/apps/otpsms/OtpSmsPage'))
  const ActivityLogPage = lazy(() => import('../modules/apps/activity-log/ActivityLogPage'))
  const ActivityLogLoginPage = lazy(() => import('../modules/apps/activity-log-login/ActivityLogLoginPage'))
  const RegisterPage = lazy(() => import('../modules/frontend/register-member/RegisterPage'))
  const LoginPage = lazy(() => import('../modules/frontend/login-member/LoginPage'))
  const OtpSmsMemberPage  = lazy(() => import('../modules/frontend/otpsms/OtpSmsMemberPage'))
  const TieCreditPage  = lazy(() => import('../modules/frontend/tiecredit/TieCreditPage'))
  const OtpSmsForTieCreditPage  = lazy(() => import('../modules/frontend/otpsms-for-tiecredit/OtpSmsForTieCreditPage'))
  const MenuMemberPage  = lazy(() => import('../modules/frontend/menu-member/MenuMemberPage'))
  const LoanAgreementPage = lazy(() => import('../modules/apps/loanagreement/LoanAgreementPage'))
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/*' element={<Navigate to='apps/user-management/users' />} />
        {/* Pages */}
        <Route path='apps/dashboard' element={<DashboardWrapper />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/admin/*'
          element={
            <SuspensedView>
              <AdminPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/consent/*'
          element={
            <SuspensedView>
              <ConsentPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/otpsms/*'
          element={
            <SuspensedView>
              <OtpSmsPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/activity-log/*'
          element={
            <SuspensedView>
              <ActivityLogPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/activity-log-login/*'
          element={
            <SuspensedView>
              <ActivityLogLoginPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/loanagreeement/*'
          element={
            <SuspensedView>
              <LoanAgreementPage />
            </SuspensedView>
          }
        />

          
        

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const PrivateRoutesMember = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  // const AdminPage = lazy(() => import('../modules/apps/admin/AdminPage'))
  // const ConsentPage = lazy(() => import('../modules/apps/consent/ConsentPage'))
  // const OtpSmsPage = lazy(() => import('../modules/apps/otpsms/OtpSmsPage'))
  const RegisterPage = lazy(() => import('../modules/frontend/register-member/RegisterPage'))
  const LoginPage = lazy(() => import('../modules/frontend/login-member/LoginPage'))
  const OtpSmsMemberPage  = lazy(() => import('../modules/frontend/otpsms/OtpSmsMemberPage'))
  const TieCreditPage  = lazy(() => import('../modules/frontend/tiecredit/TieCreditPage'))
  const OtpSmsForTieCreditPage  = lazy(() => import('../modules/frontend/otpsms-for-tiecredit/OtpSmsForTieCreditPage'))
  const MenuMemberPage  = lazy(() => import('../modules/frontend/menu-member/MenuMemberPage'))
  const AlertLoginPage  = lazy(() => import('../modules/frontend/alert-login/AlertLoginPage'))

  return (
    <Routes>
      {/* <Route element={<MasterLayout />}> */}
      {/* <Route path='/*' element={<Navigate to='/frontend/login-line/login-line' />} /> */}
      {/* <Route
          path='frontend/register/*'
          element={
            <SuspensedView>
              <RegisterPage />
            </SuspensedView>
          }
        /> */}

      
        {/* <Route
          path='alert-login'
          element={
            <SuspensedView>
              <AlertLoginPage />
            </SuspensedView>
          }
        /> */}

        <Route
          path='frontend/otpsms/*'
          element={
            <SuspensedView>
              <OtpSmsMemberPage />
            </SuspensedView>
          }
        />

        <Route
          path='frontend/tiecredit/*'
          element={
            <SuspensedView>
              <TieCreditPage />
            </SuspensedView>
          }
        />

        <Route
          path='frontend/otpsms-for-tiecredit/*'
          element={
            <SuspensedView>
              <OtpSmsForTieCreditPage />
            </SuspensedView>
          }
        />

        <Route
          path='frontend/menu-member/*'
          element={
            <SuspensedView>
              <MenuMemberPage />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />
      {/* </Route> */}
        {/* Front End */}
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes, PrivateRoutesMember}
