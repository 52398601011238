import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {LoginLineWrapper} from './login-line/LoginLine'

const registerBreadcrumbs: Array<PageLink> = [
  {
    title: 'Register',
    path: '/frontend/login-line/login-line',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LoginLinePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='login-line'
          element={
            <>
              {/* <PageTitle>Register</PageTitle> */}
              <LoginLineWrapper />
            </>
          }
        />
      </Route>
      <Route index element={<Navigate to='/frontend/login-line/login-line' />} />
    </Routes>
  )
}

export default LoginLinePage
