import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {firebaseDB} from '../../../../connect/firebase'
import {collection, addDoc, getDocs, getDoc, updateDoc, deleteDoc, query, where, onSnapshot} from '@firebase/firestore'
import {doc} from 'firebase/firestore'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  // const q = query(collection(firebaseDB, "admin"), where("email", "==", email),where("password", "==",password ));
  // const unsuscribe = onSnapshot(q, async (querySnapshot)  =>  {
    
  //   const data_snapShot = await querySnapshot.docs.map((d) => ({ id: d.id, ...d.data() }));
  //   // console.log('querySnapshot', data_snapShot[0]);
  //   return data_snapShot[0]
      
  //     // const
  // });
  // console.log(unsuscribe);
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
