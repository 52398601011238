import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'

import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
// import {getUserByToken} from './_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  saveSetAuthMember: (authMember: AuthModel | undefined) => void
  currentMember: UserModel | undefined
  checkLoginMember: any
  otpFirstLogin: string | undefined
  otpTieCredit: string | undefined
  dataBeforeSaveMember: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setCurrentMember: Dispatch<SetStateAction<UserModel | undefined>>
  setCheckLoginMember: Dispatch<SetStateAction<any>>
  setOtpFirstLogin: Dispatch<SetStateAction<string | undefined>>
  setOtpTieCredit: Dispatch<SetStateAction<string | undefined>>
  setDataBeforeSaveMember: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  logoutMember: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  saveSetAuthMember: () => {},
  currentMember: undefined,
  checkLoginMember: undefined,
  otpFirstLogin: undefined,
  otpTieCredit: undefined,
  dataBeforeSaveMember: undefined,
  setCurrentUser: () => {},
  setCurrentMember: () => {},
  setOtpFirstLogin: () => {},
  setOtpTieCredit: () => {},
  setDataBeforeSaveMember: () => {},
  setCheckLoginMember: () => {},
  logout: () => {},
  logoutMember: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [authMember, saveAuthMember] = useState<AuthModel | undefined>(authHelper.getAuthMember())
  // console.log('saveAuth', authMember);
  const [currentMember, setCurrentMember] = useState<UserModel | undefined>()

  const [checkLoginMember, setCheckLoginMember] = useState<any>()
  const [otpFirstLogin, setOtpFirstLogin] = useState<string | undefined>()
  const [otpTieCredit, setOtpTieCredit] = useState<string | undefined>()
  const [dataBeforeSaveMember, setDataBeforeSaveMember] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    // console.log('saveAuth', auth);
    const check_login = localStorage.getItem('kt-auth-react-v')
    const check_login_member = localStorage.getItem('kt-auth-member-react-v')
    // const auth2 = JSON.parse(""+check_login) 
    // console.log(auth2);
    // if(auth == undefined && check_login){
    //   auth = JSON.parse(""+check_login) 
    //   setAuth(auth)
    //   return
    // }

    // if(authMember == undefined && check_login_member){
    //   authMember = JSON.parse(""+check_login_member) 
    //   saveAuthMember(authMember)
    //   return
    // }

    // console.log(auth);
      
    // setAuth(auth)
    
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      // console.log('sasasasasa4154sa4');
      authHelper.removeAuth()
    }

    // if (authMember) {
    //   authHelper.setAuthMember(authMember)
    // } else {
    //   // console.log('sasasasasa4154sa4');
    //   authHelper.removeAuthMember()
    // }
  }

  const saveSetAuthMember = (authMember: AuthModel | undefined) => {
    // console.log('saveAuth', auth);
    const check_login = localStorage.getItem('kt-auth-member-react-v')
    // const auth2 = JSON.parse(""+check_login) 
    // console.log(auth2);
    // if(authMember == undefined && check_login){
    //   authMember = JSON.parse(""+check_login) 
    //   saveAuthMember(authMember)
    //   return
    // }

    console.log('saveSetAuthMember', authMember);
      
    saveAuthMember(authMember)

    if (authMember) {
      authHelper.setAuthMember(authMember)
    } else {
      // console.log('sasasasasa4154sa4');
      authHelper.removeAuthMember()
    }
  }

  const logout = () => {
    localStorage.removeItem('kt-auth-react-v')
    saveAuth(undefined)
    setCurrentUser(undefined)
  }
  const logoutMember = () => {
    localStorage.removeItem('kt-auth-member-react-v')
    saveAuthMember(undefined)
    setCurrentMember(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, saveSetAuthMember, currentUser, currentMember, checkLoginMember, otpFirstLogin, otpTieCredit, dataBeforeSaveMember, setCurrentUser, setCurrentMember, setOtpFirstLogin, setOtpTieCredit, setDataBeforeSaveMember, setCheckLoginMember, logout, logoutMember}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        // if (!didRequest.current) {
        //   const {data} = await getUserByToken(apiToken)
        //   if (data) {
        //     setCurrentUser(data)
        //   }
        // }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      // logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
