import React ,{FC, useState, useEffect} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {initialConsent, Consent} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {ConsentListLoading} from '../../../apps/consent/consent-list/components/loading/ConsentListLoading'
import {createConsent, updateConsent} from '../../../apps/consent/consent-list/core/_requests'

// import {useQueryResponse} from '../core/QueryResponseProvider'
import Swal from 'sweetalert2'
import MultiStep from 'react-multistep'
import './style.css'
import {ConsentFormMultiStep} from './ConsentFormMultiStep'

type Props = {
  // isConsentLoading: boolean
  consent: '' 
}



const ConsentForm: FC<Props> = ({consent}:any) => {
  const {setItemIdForUpdate} = useListView()
  const {consentRequired, setConsentRequired}:any = useListView()
  const {consentPage, setConsentPage}:any = useListView()
  const {consentFromDB, setConsentFromDB} = useListView()
  const consentDataArr:any = [] ;
  if(consent){
    const numAscending = [...consent].sort((a, b) =>
      a.consent_code > b.consent_code ? 1 : -1,
    );
    // console.log(numAscending);
    numAscending.map((val_consent:any, index:any) => {
      consentDataArr.push(val_consent);
    })
    console.log('consentDataArr', consentDataArr[consentPage].consent_code)
  }
  //const {refetch} = useQueryResponse()
  console.log('consent form', consent)
  
  

  const acceptConsent = () => {
    // if (withRefresh) {
    //   refetch()
    // }
    // setItemIdForUpdate(undefined)
    setConsentPage(consentPage + 1);
    // console.log(e.target.id);
    // consentFromDB[consentDataArr[consentPage].consent_code] = true;
    consentFromDB.push({
      'consent_code' : consentDataArr[consentPage].consent_code,
      'value' : true
    })
    // setConsentFromDB({[consentDataArr[consentPage].consent_code] : true})
  }

  const noAcceptConsent = () => {
    // if (withRefresh) {
    //   refetch()
    // }
    // setItemIdForUpdate(undefined)
    setConsentPage(consentPage + 1);
    // consentFromDB[consentDataArr[consentPage].consent_code] = false;
    // consentFromDB.push({[consentDataArr[consentPage].consent_code] : false})
    consentFromDB.push({
      'consent_code' : consentDataArr[consentPage].consent_code,
      'value' : false
    })
  }
  
  console.log('ConsentForm consentFromDB count', consentFromDB.length);
  console.log('ConsentForm consentFromDB ', consentFromDB);
  

  // consent.forEach((val_consent:any, index:any) => {
  //             {console.log(val_consent)}
  //             // const array_consent:any = {}
  //             // array_consent[index] =  val_consent;
  //             // {console.log(array_consent)}
  //             // <ConsentForm consent={data_consent} />
  // })
  // console.log('checked_consent', checked_consent);

  // const [consentForEdit] = useState<Consent>({
  //   ...consent,
  //   consent_code: consent.consent_code || initialConsent.consent_code,
  //   consent_title: consent.consent_title || initialConsent.consent_title,
  //   descrition: consent.descrition || initialConsent.descrition,
  //   status: consent.status || initialConsent.status,
  // })
  // console.log(consent);
  // var checked_consent_elem:any = undefined;
  // if(consentRequired){
  //   consentRequired.filter((required:any)=> {
  //     if(required.id === consent.consent_code){
  //         checked_consent_elem =  required.value
  //     }
  //   })
      
  // }
    // console.log('asdfsa515', checked_consent_elem);
  // const cancel = (withRefresh?: boolean) => {
  //   // if (withRefresh) {
  //   //   refetch()
  //   // }
  //   setItemIdForUpdate(undefined)
  // }

  // const changeConsent = (e:any) =>{
  //   // console.log('changeConsent' ,e);
  //   let check_consent:any = {
  //     id: e.target.id,
  //     value: (e.target.value === 'true') ? true : false
  //   }
    
  //   checked_consent_elem = check_consent.value;

  //   // console.log('on change', checked_consent_elem);

  //   if(consentRequired === undefined){
  //     setConsentRequired([
      
  //       check_consent 
  //     ]);
  //   }else{
  //     // const test = consentRequired.some(v => (v.id === check_consent.id ))
  //     // consentRequired.forEach((val:any, key:any) => {
  //     //   console.log( 'consentRequired loop',val);
  //     // });
  //     // console.log(test);
  //     // const newState = consentRequired.map((obj:any) => {
  //     //   // 👇️ if id equals 2, update country property
  //     //   if (obj.id === check_consent.id) {
  //     //     return {...obj, value: check_consent.value};
  //     //   }
  
  //     //   // 👇️ otherwise return the object as is
  //     //   return obj;
  //     // });
  //     if(consentRequired.some((v:any) => (v.id === check_consent.id )) === false){
  //       // console.log('newState',newState);
  //       setConsentRequired([
  //         ...consentRequired,
  //         check_consent
  //       ]);
  //     }else{
  //       setConsentRequired((consentRequired:any)=> {
  //         const newState = consentRequired.map((obj:any) => {
  //           // 👇️ if id equals 2, update country property
  //           if (obj.id === check_consent.id) {
  //             return {...obj, value: check_consent.value};
  //           }
    
  //           // 👇️ otherwise return the object as is
  //           return obj;
  //         });
  //         return newState;
  //       });
  //     }
  //   }

    
  // }
  // const steps:any = [];
  // if(consent){
  //   consent.map((val_consent:any, index:any) => {
  //     steps.push({
  //       title: val_consent.consent_code, 
  //       component: 
  //       // <div className="container">
  //           <div className="card-body bg-secondary">
  //             <h4>ข้อกำหนดและเงื่อนไขการยอมรับ</h4>
  //             <p className="fs-6">{val_consent.descrition}</p>
  //           </div>
  //         // </div>
  //     });
  //   })
  // }
    // {title: 'StepOne', component: 
    //   <div className="container">
    //     <div className="card-body">
    //       <p>consent01</p>
    //       <p>ข้าพเจ้าได้อ่านและรับทราบนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัท ซึ่งระบุถึงวิธีการที่บริษัท จะเก็บรวบรวม ใช้ เปิดเผย และประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้า รวมถึงรายละเอียดเพิ่มเติมเกี่ยวกับประเภทของข้อมูลส่วนบุคคลที่จะมีการเก็บรวบรวม ระยะเวลาการเก็บข้อมูลส่วนบุคคล สิทธิของเจ้าของข้อมูลส่วนบุคคล และข้อมูลอื่น ๆ จากนโยบายคุ้มครองข้อมูลส่วนบุคคลได้ที่เว็บไซต์ของบริษัท https://www.sgcapital.co.th/ รวมถึงรับทราบว่าข้าพเจ้าสามารถเพิกถอนความยินยอมที่ระบุไว้ข้างต้นได้ตลอดเวลา ผ่านช่องทางการใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลที่สามารถเข้าถึงได้ทางเว็บไซต์ของบริษัทแล้ว</p>
    //     </div>
    //   </div>
    // },
    // {title: 'StepOne', component: 
    //   <div className="container">
    //     <div className="card-body">
    //       <p>consent01</p>
    //       <p>ข้าพเจ้าได้อ่านและรับทราบนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัท ซึ่งระบุถึงวิธีการที่บริษัท จะเก็บรวบรวม ใช้ เปิดเผย และประมวลผลข้อมูลส่วนบุคคลของข้าพเจ้า รวมถึงรายละเอียดเพิ่มเติมเกี่ยวกับประเภทของข้อมูลส่วนบุคคลที่จะมีการเก็บรวบรวม ระยะเวลาการเก็บข้อมูลส่วนบุคคล สิทธิของเจ้าของข้อมูลส่วนบุคคล และข้อมูลอื่น ๆ จากนโยบายคุ้มครองข้อมูลส่วนบุคคลได้ที่เว็บไซต์ของบริษัท https://www.sgcapital.co.th/ รวมถึงรับทราบว่าข้าพเจ้าสามารถเพิกถอนความยินยอมที่ระบุไว้ข้างต้นได้ตลอดเวลา ผ่านช่องทางการใช้สิทธิของเจ้าของข้อมูลส่วนบุคคลที่สามารถเข้าถึงได้ทางเว็บไซต์ของบริษัทแล้ว</p>
    //     </div>
    //   </div>
    // },
    // {title: 'StepThree', component: <div className="card-body">55555553</div>},
    // {title: 'StepFour', component: <div className="card-body">55555554</div>}


  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  // const consentAvatarImg = toAbsoluteUrl(`/media/${ConsentForEdit.avatar}`)

  console.log('consentDataArr 2', consentDataArr);
  return (
    <>  
    {/* {console.log('consentPage', consentPage)} */}
      <div className="container-fluid">
        {/* {consent !== null &&
          // <MultiStep activeStep={consentPage} showNavigation={false} steps={steps} showTitles={false}  />
          <ConsentFormMultiStep consent={consent} pageConsent={consentPage}/>
        } */}
        { consentDataArr[consentPage]   && (
          <div className="card-body bg-secondary mt-10">
              <h4>ข้อกำหนดและเงื่อนไขการยอมรับ</h4>
              <p className="fs-6">{consentDataArr[consentPage].descrition}</p>
          </div>
          
          
        )}
        { consentDataArr[consentPage]  && (
            (consentDataArr[consentPage].consent_required  === true) ? (
            <>
              <div className="d-grid gap-2 pt-10">
                <button className="btn btn-primary btn-lg rounded-pill"  type="button" onClick={acceptConsent}>ยอมรับ</button>
              </div>
            </>
            )
              :
            (
              <>
                <div className="col-12 row d-flex justify-content-center pt-10 m-0">
                  <div className="col-6">
                      <button className="btn btn-danger btn-lg rounded-pill w-100"  type="button" onClick={noAcceptConsent}>ไม่ยอมรับ</button>
                  </div>
                  <div className="col-6">
                      <button className="btn btn-primary btn-lg rounded-pill w-100"  type="button" onClick={acceptConsent}>ยอมรับ</button>
                  </div>
                </div>
              </>
            )
          )}
      </div>
    </>
  )
}

export {ConsentForm}
