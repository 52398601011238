
import {ListViewProvider, useListView} from '../core/ListViewProvider'
// import {QueryRequestProvider} from './core/QueryRequestProvider'
// import {QueryResponseProvider} from './core/QueryResponseProvider'
// import {AdminListHeader} from './components/header/AdminListHeader'
import {RegisterLoading} from '../components/loading/RegisterLoading'
import {RegisterForm} from '../form/RegisterForm'
import {ConsentModalShow} from '../modal/ConsentModalShow'
import {KTCard} from '../../../../../_metronic/helpers'
import {useState} from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider, gql, useQuery} from '@apollo/client'
import {ConsentForm} from '../form/ConsentForm'
import { useNavigate } from "react-router-dom";
// import { HttpLink } from 'apollo-link-http';
// const link = new HttpLink({ uri: 'http://127.0.0.1:5001/reactjs-function/asia-southeast1/graphql' });
import {useConsentList} from '../core/ConsentList'
const client = new ApolloClient({
  uri: 'https://asia-southeast1-uat-mkt.cloudfunctions.net/graphql',
  cache: new InMemoryCache(),
});



// const GET_CUSTOMER_PAGE = gql`
// query ExampleQuery($limit: Int!, $offset: Int!){
//   customer (limit: $limit, offset: $offset){
//     id
//     firstname
//     lastname
//     email
//     position
//     role
//   }
// }
// `;

const Register = () => {
  const {itemIdForUpdate} = useListView()

  const navigate = useNavigate();
  const {consentFromDB, setConsentFromDB} = useListView()
  const data_consent_list = useConsentList();
  // if(consentRequired !== undefined){

  let count_consent = null
  let data_consent:any = null;
  if(data_consent_list.loading === false){
    // console.log('consentCheck count', data_consent_list);
    count_consent = data_consent_list.data.consentByStatus.length;
    data_consent = data_consent_list.data.consentByStatus;

    // data_consent_list.data.consent.forEach((val:any,index:any) => {
    //   consentFromDB[index] = {[val.consent_code] : null, 'step': 'no'}
    // })
  }
  const lineUserID = window.sessionStorage.getItem("lineUserID");
  console.log('lineUserID', lineUserID);
  console.log('resister consent', data_consent_list);



  if(lineUserID === null){
    navigate('/frontend/login-line/login-line')

  }
  // }
  // const [page, setPage] = useState(0);
  // const {data, loading, error} = useQuery(GET_CUSTOMER_PAGE,{
  //   client,
  //   variables: {
  //     limit: 10,
  //     offset: page * 10,
  //   }

  // })
  // console.log('consentFromDB count Register', consentFromDB.length);
  // console.log('consentFromDB Register', consentFromDB);
  // console.log(data_consent);
  // if(consentFromDB){
    // consentFromDB.forEach((val_consent:any, index:any) => {
        // console.log('consentCheck', consentFromDB);
    // })

  //   Object.entries(consentFromDB).map(([key, value]) => {

  //     console.log('key name = ', key);

  //   })
  // }

    return (
      <>
        <KTCard>
          {/* <AdminListHeader /> */}

          {(count_consent === consentFromDB.length) && <RegisterForm />}
        </KTCard>
        {(data_consent !== undefined && count_consent !== consentFromDB.length) && <ConsentForm consent={data_consent}/>}
        {(data_consent_list.loading === true) && <RegisterLoading />}

      </>
    )
}

const RegisterWrapper = () => (
  <ApolloProvider client={client}>
{/* //     <QueryRequestProvider> */}
{/* //       <QueryResponseProvider> */}
        <ListViewProvider>
          <Register />
        </ListViewProvider>
{/* //       </QueryResponseProvider> */}
{/* //     </QueryRequestProvider> */}
    </ApolloProvider>
)

export {RegisterWrapper}
