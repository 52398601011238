import {FC} from 'react'
import liff from '@line/liff';
import {Logout, AuthPage, useAuth} from '../../../modules/auth'
import { useNavigate } from "react-router-dom";
const Error404: FC = () => {
  const navigate = useNavigate();
  const {currentMember}:any = useAuth()
  console.log(currentMember);
  if(currentMember){
    navigate('/frontend/menu-member/menu-member');
  }
  return (
    <>
      <h1 className='fw-bolder fs-4x text-gray-700 mb-10'>Page Not Found</h1>

      <div className='fw-bold fs-3 text-gray-400 mb-15'>
        The page you looked not found! <br /> Plan your blog post by choosing a topic
      </div>
    </>
  )
}

export {Error404}
