import React from 'react';
import axios, {AxiosResponse} from 'axios'
// import {ID, Response} from '../../../../../../_metronic/helpers'
// import {Consent, ConsentQueryResponse} from './_models'
import {firebaseDB} from '../../../../../connect/firebase'
import {collection, addDoc, getDocs, getDoc, updateDoc, deleteDoc, query, onSnapshot} from '@firebase/firestore'
import {doc, where} from 'firebase/firestore'
// import {useConsentList} from '../core/ConsentList'


const API_URL = process.env.REACT_APP_THEME_API_URL
const Consent_URL = `${API_URL}/consent`
const GET_Consent_URL = `${API_URL}/consent/query`

const updateLoglogin = async (val: string | any) =>  {
  console.log('createMember',val);
  const id_act= String(val);
  // const id_Str = JSON.stringify(user.id);
  const ref = doc(firebaseDB, 'activity_log_login', id_act);
    const res = updateDoc(ref,{
      login_status: false
      } ).then(response => {
        // alert("updated")
        console.log(response);
        // return response;
        return response;
      }).catch(error =>{
        console.log(error.message)
        return error.message;
      })

  return Promise.resolve(res)
  // try {
  //   // await addDoc(collection(firebaseDB, 'customer'), {
  //   //   title_name: val.title_name,
  //   //   firstname: val.firstname,
  //   //   lastname: val.lastname,
  //   //   email: val.email,
  //   //   phone: val.phone,
  //   //   id_card: val.id_card,
  //   //   line_user_login: (val.line_user_login) ? val.line_user_login : '',
  //   //   role: 'member',
  //   //   date_of_birth: val.year_of_birth + '-' +  val.month_of_birth + '-'  + val.day_of_birth,
  //   //   get_news: JSON.stringify(val.get_news),
  //   //   accept_consent: JSON.stringify(val.arr_consent)
  //   // })
  //   // onClose()
  // } catch (err) {
  //   alert(err)
  // }



}



export {updateLoglogin}
